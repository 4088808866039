export const pages = {
    1: {
      1: "Водиш ли здравословен начин на живот, но през цялото време си начумерен и навъсен, ще си по-зле от гламавия, правещ умерени глупости, но е в покой и мир със себе си и естествено е закичил весела физиономия. ",
      2: "Преодоляването на недостатък е като да изкачваш осемхилядник. Много трудно е и постоянно се питаш дали усилията си заслужават, докато съмненията след покоряването на върха могат и да останат, то при постигането на положителна промяна в живота ти – такива няма.",
      3: "Това, което можеш да си причиниш с вилица и лъжица не като се бодеш или удряш с тях, а просто като ги използваш по предназначение, пъхайки храна в устата си, е равносилно на това да дадеш тялото си за експерименти на садистични учени.",
      4: "Едни от най-опасните оръжия за самоунищожение, което човечеството е създало, са вилицата и лъжицата или поне много хора ги използват с такава цел.",
      5: "Когато целта превишава възможностите, е кръстопът, от който може да се озовеш по пътя на разочарованието или по този на твоето израстване.",
      6: "Най-малтретираното нещо на света е денят на нещастника, който вместо с камъни е замерян безпощадно с тягостните минути.",
    },
    73: {
      470: "Когато ходиш на работа, внимавай за какво ти плащат най-много. За труда, който полагаш, или за здравето, което им оставяш. Няма заплата, която да си заслужава да изтъргуваш здравето си за нея.",
      471: "Най-ценната заплата е тази от работата, на която ставаш по-силен, по-здрав и по-умен, ако ще и да е минимална.",
      472: "Ако за това, за което ти плащат, се смаляваш като човек, то значи, че за получената заплата им се отплащаш с частица от теб всеки ден (път).",
      473: "Ако от това, което правя, изкарвам много пари, но се превръщам от него в маймуна, за какво ми е, като вече ми трябват само банани.",
      474: "Да си плащаш със здравето, за да изкарваш пари, не се нарича работа, а почасово самоубийство.",
      475: "Да се родиш не означава, че ще живееш, а че е стартирало времето, през което може да го направиш.",
      476: "Смислен живот е този, в който се опитваш да си по-добър от вчера с цялото си сърце.",
      477: "Свободата е да търчиш навсякъде в килията, изградена с решетки от норми.",
    },
    105: {
      677: "Човек обича да подритва неща в краката си, от които един ден изпитва крайна нужда (например здравето).",
      678: "Никой не иска да си върнеш тялото неизползвано, а – изхабено и изгоряло в полза на смислен живот, не в гуляи и пороци.",
      679: "Убиеш ли повечето от дните си, миналото ще ти се превърне в гробище, което никой не посещава и полага цветя, включително и ти самият, а там ще има задължително място за края ти, но вместо да ги убиваш, може да ги накараш да се прераждат в хубави спомени и така миналото ти ще заприлича на цветна градина, в която един ден с удоволствие ще искаш да се разхождаш.",
      680: "Вместо да гребеш срещу течението, решиш да се пуснеш по него, може да ти се наложи после да гребеш с метла по улиците.",
      681: "Недей кри егото си в мъглата на илюзията, че реагираш винаги перфектно.",
      682: "Само когато се изправиш пред демоните си, осъзнаваш, че са ангели, подложили те на тест, за да видят дали заслужаваш по-добра участ.",
      683: "Ако поради начина на живот миналото ти не струва, то с един-два дни (различни) в настоящето не се надявай да стане кой знае какво, но оттам трябва да тръгнеш.",
    }
  };