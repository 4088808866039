import { Link } from 'react-router-dom';
import styles from './Footer.module.css'

export const Footer = () => {
  return (
    <div style={{backgroundColor: 'black', borderTop: '2px solid #f8b11a'}}>
        <div className="container">
        <footer
            className="text-center text-lg-start text-white"
            style={{backgroundColor: 'black'}}
        >
            <div className="container p-4 pb-0">
            <section className="">
                <div className="row">
                    <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                        <h6 className={`text-uppercase mb-4 font-weight-bold ${styles.title}`}>
                            МЪДРОСТИ ОТ ПЕПЕЛТА
                        </h6>
                        <p>
                            Мъдрости от Пепелта е сборник от сентенции, подбрани с цел да вдъхновяват и мотивират читателите. 
                        </p>
                    </div>

                    <hr className="w-100 clearfix d-md-none" />

                    <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
                        <h6 className={`text-uppercase mb-4 font-weight-bold ${styles.title}`}>ПРАВИЛА</h6>
                        <p>
                            <Link to="/terms" target="_blank" className="text-white">Общи условия</Link>
                        </p>
                        <p>
                            <Link to="/personal-info-rules" target="_blank" className="text-white">Лични данни</Link>
                        </p>
                    </div>

                    <hr className="w-100 clearfix d-md-none" />

                    <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                        <h6 className={`text-uppercase mb-4 font-weight-bold ${styles.title}`}>КОНТАКТИ</h6>
                        <p>
                        <i className="fas fa-home mr-3"></i> Велико Търново, ВТ 5000, BG
                        </p>
                        <p>
                        <i className="fas fa-envelope mr-3"></i> madrostiotpepelta@gmail.com
                        </p>
                        <p>
                        <i className="fas fa-phone mr-3"></i> +359 882 511 650
                        </p>
                    </div>

                    <hr className="w-100 clearfix d-md-none" />

                    <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                        <h6 className={`text-uppercase mb-4 font-weight-bold ${styles.title}`}>ПОСЛЕДВАЙТЕ НИ</h6>

                        <a
                        className="btn btn-dark btn-floating m-1"
                        style={{backgroundColor: '#3b5998', borderRadius: '35px'}}
                        href="https://www.facebook.com/profile.php?id=61554567992121"
                        role="button"
                        >
                        <i className="fab fa-facebook-f"></i>
                        </a>


                        <a
                        className="btn btn-dark btn-floating m-1"
                        style={{backgroundColor: '#ac2bac', borderRadius: '35px'}}
                        href="https://www.instagram.com/madrostiotpepelta/"
                        role="button"
                        >
                        <i className="fab fa-instagram"></i>
                        </a>

                        <a
                        className="btn btn-dark btn-floating m-1"
                        style={{backgroundColor: 'red', borderRadius: '35px'}}
                        href="https://www.youtube.com/channel/UCbN5YSCiFYvDY_9vp6g5C6w"
                        role="button"
                        >
                        <i className="fab fa-youtube"></i>
                        </a>

                    </div>
                </div>
            </section>
            </div>

            <div
            className="text-center p-3"
            style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}
            >
            © 2023 Copyright:&nbsp;
            <a className="text-white" href="https://wisdomfromtheashes.eu/">
              WisdomFromTheAshes.eu
            </a>
            </div>
        </footer>
        </div>
    </div>
  );
};
