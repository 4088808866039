import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';

import { Routes, Route } from 'react-router-dom';

import { Footer } from './components/Footer/Footer';
import { Main } from './components/Main/Main';
import { Terms } from './components/Terms/Terms';
import { PersonalInformation } from './components/PersonalInformation/PersonalInformation';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Main />}/>
        <Route path="/terms" element={<Terms />} />
        <Route path="/personal-info-rules" element={<PersonalInformation />} />
      </Routes>
        <Footer />
    </>
  );
}

export default App;
