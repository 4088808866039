import styles from './Landing.module.css';

export const Landing = () =>{
    return(
        <header>          
            <div className={styles.hero}>
                <h1 className={styles.title}>МЪДРОСТИ ОТ ПЕПЕЛТА</h1>
                <p className={styles.desc}>Когато живота ти, от почти половин век, си превърнал в тягостна, изгаряща болка, накрая се чувстваш като пепел, която има какво да каже на другите въглени.</p>
                <div className={styles.subtitleContainer}>
                    <p className={styles.subtitle}>Красимир Костадинов</p>
                </div>              
             </div>
        </header>       
    )
}